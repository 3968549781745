import { FC, Suspense } from 'react';

import { Route } from 'react-router-dom';
import { TRoutes } from 'routers';
import { ERoutes } from 'shared/enums/routes';
import { NoRequireAuth, RequireAccess, RequireAuth } from 'shared/guards/auth.guard';
import { useIsIncludesInPathname } from 'shared/hooks/useIsIncludesInPathname';
import PageLoader from 'shared/ui/PageLoader';

type TRoutesElementProps = {
	route: TRoutes;
};

const RoutesElement: FC<TRoutesElementProps> = ({ route }): JSX.Element => {
	const { isAuth, Component, isHasAccess } = route;
	const isPublicRoute = useIsIncludesInPathname({ path: ERoutes.public });

	if (isPublicRoute) {
		return <Component />;
	}

	if (isAuth) {
		return (
			<RequireAuth>
				<RequireAccess isHasAccess={!!isHasAccess}>
					<Suspense fallback={<PageLoader />}>
						<Component />
					</Suspense>
				</RequireAccess>
			</RequireAuth>
		);
	} else {
		return (
			<NoRequireAuth>
				<Suspense fallback={<PageLoader />}>
					<Component />
				</Suspense>
			</NoRequireAuth>
		);
	}
};

export const renderRoutes = (values: TRoutes[]): JSX.Element => {
	return (
		<>
			{values.map((route) => {
				const { path, children, index } = route;

				if (children?.length) {
					return (
						<Route path={path} key={path} element={<RoutesElement route={route} />}>
							{renderRoutes(children)}
						</Route>
					);
				}

				return (
					<Route
						index={index}
						path={path}
						key={path ?? crypto.randomUUID()}
						element={<RoutesElement route={route} />}
					/>
				);
			})}
			;
		</>
	);
};
