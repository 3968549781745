import { GoogleOAuthProvider } from '@react-oauth/google';
import ErrorPage from 'pages/ErrorPage';
import { ErrorBoundary } from 'react-error-boundary';
import {
	Navigate,
	Route,
	RouterProvider,
	Routes,
	createBrowserRouter,
	createRoutesFromElements,
} from 'react-router-dom';
import { renderRoutes } from 'routers/RenderRoutes/RenderRoutes';
import { BreadcrumbsProvider } from 'shared/context/breadcrumbs';
import { ConfluenceOauthProvider } from 'shared/context/confluenceOauth';
import { InitializationProvider } from 'shared/context/initialization';
import { LayoutProvider } from 'shared/context/layout';
import { OAuthContextProvider } from 'shared/context/oauthContext';
import useStartOnboarding from 'shared/context/onboardingContext/hooks/useStartOnboarding';
import { OnboardingProvider } from 'shared/context/onboardingContext/onboardingContext';
import { ONBOARDING_GUIDE_MODULES } from 'shared/enums/onboardingGuideModules';
import { ERoutes, ERoutesFullPath } from 'shared/enums/routes';
import AuthConsumer, { AuthProvider } from 'shared/guards/auth.guard';
import usePageTracking from 'shared/hooks/usePageTracking';
import useRouterParams from 'shared/hooks/useRouterParams';
import checkIsPathMatch from 'shared/lib/checkIsPathMatch';
import MessageDisplayer from 'shared/ui/MessageDisplayer/MessageDisplayer';
import ScrollToTop from 'shared/ui/ScrollToTop';

import Layout from './layouts/Layout';
import { useGetPublicRoutes, useGetRoutes } from './routers';
import './styles/main.scss';

const Root = (): JSX.Element => {
	const { routes } = useGetRoutes();
	const { user, isUserLoading } = AuthConsumer();
	const { workspaceId } = useRouterParams();

	const isHomePage = checkIsPathMatch(workspaceId, ERoutesFullPath.home);
	const isRootPage = checkIsPathMatch(workspaceId, '/', true);
	const shouldStartOnboarding =
		!isRootPage &&
		!isHomePage &&
		!!user?.onboardingGuides &&
		!user.onboardingGuides.SIDE_MENU &&
		!isUserLoading;

	usePageTracking();
	useStartOnboarding(ONBOARDING_GUIDE_MODULES.SIDE_MENU, shouldStartOnboarding);

	return (
		<ErrorBoundary FallbackComponent={ErrorPage}>
			<Routes>
				{renderRoutes(routes)}
				<Route path="*" element={<Navigate to={ERoutes.login} />} />
			</Routes>
		</ErrorBoundary>
	);
};

const PublicRoot = (): JSX.Element => {
	const { publicRoutes } = useGetPublicRoutes();
	usePageTracking();

	return (
		<ErrorBoundary FallbackComponent={ErrorPage}>
			<Routes>
				{renderRoutes(publicRoutes)}
				<Route path="*" element={<Navigate to={ERoutesFullPath.public_not_found} />} />
			</Routes>
		</ErrorBoundary>
	);
};

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route
			path="*"
			element={
				<GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
					<ConfluenceOauthProvider>
						<OAuthContextProvider>
							<AuthProvider>
								<LayoutProvider>
									<InitializationProvider>
										<>
											<ScrollToTop />
											<MessageDisplayer />
											<OnboardingProvider>
												<BreadcrumbsProvider>
													<Layout>
														<Root />
													</Layout>
												</BreadcrumbsProvider>
											</OnboardingProvider>
										</>
									</InitializationProvider>
								</LayoutProvider>
							</AuthProvider>
						</OAuthContextProvider>
					</ConfluenceOauthProvider>
				</GoogleOAuthProvider>
			}
		/>,
	),
);

const publicRouter = createBrowserRouter(
	createRoutesFromElements(
		<Route
			path="*"
			element={
				// have to wrap PublicRoot in AuthProvider and InitializationProvider to not duplicate components
				// with auth and initialization logic, to use same components for public pages and for private
				<AuthProvider>
					<LayoutProvider>
						<InitializationProvider>
							<PublicRoot />
						</InitializationProvider>
					</LayoutProvider>
				</AuthProvider>
			}
		/>,
	),
);

const App = (): JSX.Element => {
	const { pathname } = window.location;

	const routerToRender = pathname.includes(ERoutes.public) ? publicRouter : router;

	return <RouterProvider router={routerToRender} />;
};

export default App;
