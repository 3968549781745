import { useMemo } from 'react';

import { concatUrl } from 'routers';
import { ACCESS_LEVEL_TYPES } from 'shared/api/users.service';
import InitializationConsumer from 'shared/context/initialization';
import { ERoutes, ERoutesFullPath } from 'shared/enums/routes';
import { SUBSCRIPTION_STATUSES } from 'shared/enums/subscriptionStatus';
import AuthConsumer from 'shared/guards/auth.guard';
import { SUBSCRIPTIONS_PLANS } from 'shared/types/subscriptionPlansTypes';

import useRouterParams from './useRouterParams';

export const useGetNavigatePathDueToPlan = (): {
	navigatePath: ERoutes | ERoutesFullPath | string;
} => {
	const { user } = AuthConsumer();
	const { engId } = useRouterParams();
	const { initialization } = InitializationConsumer();

	const navigatePath = useMemo(() => {
		if (user && !user?.workspaces?.length) {
			const engId = user?.currentEngagement?.id || user?.engagementIds?.[0] || '';

			return `/${engId}/${ERoutesFullPath.no_workspace}`;
		}

		if (user?.accessLevelType === ACCESS_LEVEL_TYPES.SYSTEM_ADMIN) {
			return ERoutesFullPath.users_management;
		}

		if (initialization && initialization?.subscriptionStatus !== SUBSCRIPTION_STATUSES.ACTIVE) {
			return ERoutesFullPath.inactive_subscription;
		}

		if (initialization?.subscriptionPlans.includes(SUBSCRIPTIONS_PLANS.ENTERPRISE)) {
			return ERoutesFullPath.home;
		}

		return concatUrl(
			[engId!, user?.defaultWorkspaceId ?? '', ERoutesFullPath.workspace_details],
			true,
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, initialization?.subscriptionPlans]);

	return { navigatePath };
};
