enum EUrlParams {
	id = ':id',
	engId = ':engId',
	diagramId = ':diagramId',
	planId = ':planId',
	oppModelId = ':oppModelId',
	optimizationId = ':optimizationId',
	workspaceId = ':workspaceId',
	retrospectiveId = ':retrospectiveId',
	projectId = ':projectId',
	optId = ':optId',
}

export default EUrlParams;
