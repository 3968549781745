import { useEffect, useState } from 'react';

import checkIsMobile from 'is-mobile';

export type TMobileViewState = { isMobile: boolean; isTablet: boolean; isMobileOrTablet: boolean };
const isMobile = checkIsMobile() || window.innerWidth < 768;
const isTablet = checkIsMobile({ tablet: true }) || (!isMobile && window.innerWidth < 1024);

const useIsMobile = (): TMobileViewState => {
	const [isMobileState, setIsMobileState] = useState(getMobileViewState);

	useEffect(() => {
		const handleResize = (): void => setIsMobileState(getMobileViewState());

		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return isMobileState;
};

const getMobileViewState = (): TMobileViewState => ({
	isMobile,
	isTablet,
	isMobileOrTablet: isMobile || isTablet,
});

export default useIsMobile;
