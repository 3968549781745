import { createQueryKeyStore } from '@lukemorales/query-key-factory';
import { UseMutationResult, useMutation } from '@tanstack/react-query';
import axios, { API_PREFIX } from 'shared/appConfigs/axios';
import { ONBOARDING_GUIDE_MODULES } from 'shared/enums/onboardingGuideModules';
import { TPagination } from 'shared/types/paginationTypes';
import { TUserDetails } from 'shared/types/userDetailsTypes';
import { AllFilterParams } from 'shared/ui/Filter/useFilterHelper';

import { TUserSettings, TUserSideMenuItem } from './auth.service';

export enum ACCESS_LEVEL_TYPES {
	COLLABORATOR = 'COLLABORATOR',
	PRACTITIONER = 'PRACTITIONER',
	OWNER = 'OWNER',
	TEAM_ADMIN = 'TEAM_ADMIN',
	SYSTEM_ADMIN = 'SYSTEM_ADMIN',
}

export enum ACCESS_LEVEL_TYPES_TO_SHOW {
	COLLABORATOR = 'Collaborator',
	PRACTITIONER = 'Practitioner',
	OWNER = 'Owner',
	SYSTEM_ADMIN = 'System Admin',
	TEAM_ADMIN = 'Team Admin',
}

export enum USER_STATUS {
	ACTIVATE = 'activate',
	DEACTIVATE = 'deactivate',
}

export enum USER_TYPES {
	GUEST = 'GUEST',
	USER = 'USER',
}

export type TUserStatus = USER_STATUS.ACTIVATE | USER_STATUS.DEACTIVATE;
export type TAccessLevelType = keyof typeof ACCESS_LEVEL_TYPES;

export type TAddNewUserValues = {
	accessLevelType: TAccessLevelType;
	address: {
		address: string;
		city: string;
		country: string;
		usaStateType: string;
		zipCode: string;
	};
	email: string;
	firstName: string;
	jobTitle: string;
	lastName: string;
	organizationId: string;
};

export type TUser = {
	accessLevelType: TAccessLevelType;
	accountStatus: string;
	address: {
		city: string;
		usaStateType: string;
		country: string;
		zipCode?: string;
		address?: string;
	};
	email: string;
	engagementIds?: string[];
	firstName: string;
	id: string;
	jobTitle: string;
	lastName: string;
	onboardedAt: string;
	phoneNumber?: string;
};

export type TUserOwner = {
	email: string | null;
	firstName: string | null;
	lastName: string | null;
};

type TUsers = TPagination & { content: TUser[] };

export type TUpdateSideItemsPayload = {
	sideMenuItems: TUserSideMenuItem[];
};

export const usersQueryKeys = createQueryKeyStore({
	users: {
		list: ({ page, size, search, sort, filters }) => [page, size, search, sort, filters],
		owner: null,
		user: (id) => [id],
		details: ({ workspaceId, accessLevelTypes, excludeMembersOfWorkspaceId }) => [
			workspaceId,
			accessLevelTypes,
			excludeMembersOfWorkspaceId ?? undefined,
		],
	},
});

export const getUsersDetails = async (params = {}): Promise<TUserDetails[]> => {
	const { data } = await axios.get<TUserDetails[]>(`${API_PREFIX}/users/details`, { params });
	return data;
};

const createUserService = async (values: TAddNewUserValues): Promise<TUsers> => {
	const { data } = await axios.post<TUsers>(`${API_PREFIX}/users`, values);
	return data;
};

export const useCreateUser = (): UseMutationResult<any> =>
	useMutation((values: TAddNewUserValues) => createUserService(values));

export const getUsersList = async (params: AllFilterParams): Promise<TUsers> => {
	const { data } = await axios.get<TUsers>(`${API_PREFIX}/users`, { params });
	return data;
};

export const resendInvitation = async (id: string): Promise<void> => {
	await axios.post<void>(`${API_PREFIX}/users/${id}/invitation`);
};

export const getUser = async (id: string): Promise<TUser> => {
	const { data } = await axios.get<TUser>(`${API_PREFIX}/users/${id}`);
	return data;
};

export const editUser = async (id: string, values: object): Promise<{ [key: string]: string }> => {
	const { data } = await axios.patch<{ [key: string]: string }>(
		`${API_PREFIX}/users/${id}`,
		values,
	);
	return data;
};

export const changeUserStatus = async (id: string, status: TUserStatus): Promise<void> => {
	await axios.put<void>(`${API_PREFIX}/users/${id}/${status}`);
};

export const toggleVoiceChat = async (): Promise<TUserSettings> => {
	const { data } = await axios.put<TUserSettings>(`${API_PREFIX}/users/user-settings/voice-chat`);
	return data;
};

export const deleteUser = async (userId: string): Promise<void> => {
	const { data } = await axios.delete(`${API_PREFIX}/users/${userId}`);
	return data;
};

export const getUserOwner = async (): Promise<TUserOwner> => {
	const { data } = await axios.get<TUserOwner>(`${API_PREFIX}/users/owner`);
	return data;
};

export const updateUserOwner = async (userId: string): Promise<TUserOwner> => {
	const { data } = await axios.patch<TUserOwner>(`${API_PREFIX}/users/owner?userId=${userId}`);
	return data;
};

export const updateSideItems = async (
	payload: TUpdateSideItemsPayload,
): Promise<TUpdateSideItemsPayload> => {
	const { data } = await axios.put<TUpdateSideItemsPayload>(
		`${API_PREFIX}/users/user-settings`,
		payload,
	);
	return data;
};

export const toggleIsVoiceChatActive = async (): Promise<void> => {
	await axios.put(`${API_PREFIX}/users/user-settings/voice-chat`);
};

export const updateShowJiraInvitation = async (): Promise<void> => {
	await axios.post<void>(`${API_PREFIX}/users/jira-invitation`);
};

export const completeOnboarding = async (module: ONBOARDING_GUIDE_MODULES): Promise<void> => {
	await axios.post(`${API_PREFIX}/users/guide`, {
		onboardingGuideType: module,
	});
};

export const toggleSideMenuGroup = async (sideMenuGroupType: string): Promise<void> => {
	await axios.put(`${API_PREFIX}/users/user-settings/side-menu-groups/collapse`, {
		sideMenuGroupType,
	});
};
