import { matchPath } from 'react-router-dom';
import { ERoutesFullPath } from 'shared/enums/routes';
import EUrlParams from 'shared/enums/urlParams';

const checkIsPathMatch = (
	workspaceId: string | undefined,
	path: ERoutesFullPath | '/',
	end = false,
): boolean => {
	const workspaceIdParam = workspaceId ? `/${workspaceId}` : '';
	return !!matchPath(
		{ path: `${EUrlParams.engId}${workspaceIdParam}/${path}`, end },
		location.pathname,
	);
};

export default checkIsPathMatch;
