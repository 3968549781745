import { useState } from 'react';

import { createQueryKeyStore } from '@lukemorales/query-key-factory';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { MenuProps } from 'antd';
import { AppInitializationResponse } from 'generated-types';
import { omit, startCase } from 'lodash';
import axios, { API_PREFIX } from 'shared/appConfigs/axios';
import useRouterParams from 'shared/hooks/useRouterParams';
import { TDetail } from 'shared/types/dataDetailsType';

import InitializationPropertiesModel from '../models/initialization-properties.model';

export type TAllDetails = {
	organization: TDetail;
	plans: TDetail[];
	projects: TDetail[];
	optimizations: TDetail[];
	diagrams: TDetail[];
	storyboards: TDetail[];
};

export const getInitialization = async (): Promise<AppInitializationResponse> => {
	const { data } = await axios.get<AppInitializationResponse>(`${API_PREFIX}/initialization`);
	return data;
};

export type ReturnInitialization = {
	initialization: InitializationPropertiesModel | null;
	setInitialization: (initialization: InitializationPropertiesModel) => void;
	initializationLoading: boolean;
	refetchInitialization?: any;
};

export const useInitialization = (): ReturnInitialization => {
	const [initialization, setInitialization] = useState<InitializationPropertiesModel | null>(null);

	return {
		initialization,
		setInitialization,
		initializationLoading: false,
	};
};

export const initializationQueryKeys = createQueryKeyStore({
	initialization: {
		details: (arg) => [arg],
		hashtagOptions: ({ workspaceId }) => [workspaceId],
	},
});

export const getHashtagOptions = async (params = {}): Promise<TAllDetails> => {
	const { data } = await axios.get<TAllDetails>(`${API_PREFIX}/initialization/hashtag-options`, {
		params,
	});
	return data;
};

export const useGetHashtagOptions = (
	excludedOptions: string[] = [],
): UseQueryResult<MenuProps['items']> => {
	const { workspaceId } = useRouterParams();

	return useQuery({
		...initializationQueryKeys.initialization.hashtagOptions({ workspaceId }),
		queryFn: () => getHashtagOptions({ workspaceId }),
		select: (data) => {
			const excludeOptionsData = omit(data, ['organization', ...excludedOptions]);

			return Object.entries(excludeOptionsData).map(([key, value]) => {
				return {
					key: key.replace(/([A-Z])/g, '_$1').toUpperCase(),
					label: startCase(key),
					...(Array.isArray(value) &&
						!!value?.length && {
							children: value.map((item) => ({ key: item.id, label: item.name })),
						}),
				};
			});
		},
	});
};
