import { PropsWithChildren, ReactElement, createContext, useContext } from 'react';

import useIsMobile, { TMobileViewState } from 'shared/hooks/useIsMobile';

type TLayoutContext = TMobileViewState;

const LayoutContext = createContext<TLayoutContext | undefined>(undefined);

export const LayoutProvider = ({ children }: PropsWithChildren): ReactElement => {
	const state = useIsMobile();

	return <LayoutContext.Provider value={state}>{children}</LayoutContext.Provider>;
};

export default function LayoutConsumer(): TLayoutContext {
	const context = useContext(LayoutContext);

	if (!context) {
		throw new Error('LayoutConsumer must be used within a LayoutProvider');
	}

	return context;
}
