import { Params, useParams } from 'react-router-dom';
import EUrlParams from 'shared/enums/urlParams';

type TUrlParamsKeys = keyof typeof EUrlParams;

const useRouterParams = (): Params<TUrlParamsKeys> => {
	return useParams<TUrlParamsKeys>();
};

export default useRouterParams;
