import { ThemeConfig } from 'antd';

export const themeConfig: ThemeConfig = {
	hashed: false,
	token: {
		colorBgBase: '#fff',
		colorText: '#1a2026',
		colorPrimaryBg: '#fff',
		colorPrimary: '#00AC8E',
		colorPrimaryHover: '#33BDA5',
		colorPrimaryActive: '#006162',
		colorError: '#D03900',
		colorErrorHover: 'rgba(208, 57, 0, 0.6)',
		colorErrorActive: '#9A2A00',
		colorLink: '#00AC8E',
		fontFamily: `'Jost', sans-serif`,
		fontSize: 14,
		colorTextHeading: '#1A2026',
		colorTextBase: '#1A2026',
		colorTextSecondary: '#1A2026',
		colorTextDisabled: '#BFBFBF',
		colorBgContainerDisabled: '#BFBFBF',
		colorTextPlaceholder: '#8C8C8C',
		borderRadius: 6,
		colorBorder: '#D9D9D9',
		colorBgLayout: '#fff',
		colorPrimaryBorder: '#00AC8E',
		colorPrimaryBorderHover: '#33BDA5',
	},
	components: {
		Layout: {
			headerBg: '#fff',
			headerHeight: 60,
			siderBg: '#fff',
		},
		Button: {
			fontSize: 14,
			fontWeight: 500,
			controlHeight: 40,
			primaryShadow: 'none',
			colorPrimaryHover: '#33BDA5',
			paddingContentHorizontal: 16,
		},
		Dropdown: {
			borderRadiusLG: 6,
			borderRadiusSM: 6,
			borderRadiusXS: 6,
		},
		Card: {
			borderRadius: 12,
		},
		Select: {
			optionSelectedColor: '#fff',
			optionSelectedFontWeight: 400,
			optionSelectedBg: '#00AC8E',
			controlHeight: 40,
		},
		Table: {
			headerBg: '#FAFAFA',
			rowHoverBg: '#FAFAFA',
		},
		Typography: {
			titleMarginBottom: '24px',
			fontSizeHeading1: 24,
			lineHeightHeading1: 0.91,
		},
		Form: {
			labelFontSize: 16,
			labelHeight: 22,
			controlHeight: 40,
		},
		Input: {
			controlHeight: 40,
		},
		Alert: {
			colorErrorBg: '#fff1f0',
			colorErrorBorder: '#ffa39e',
		},
		Modal: {
			borderRadiusLG: 6,
			borderRadiusSM: 6,
		},
	},
};
